exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ra-031-02-startup-ptn-b-js": () => import("./../../../src/pages/ra_031-02_startup_ptn_b.js" /* webpackChunkName: "component---src-pages-ra-031-02-startup-ptn-b-js" */),
  "component---src-pages-ra-033-01-startup-pc-ptn-b-js": () => import("./../../../src/pages/ra_033-01_startup_pc_ptn_b.js" /* webpackChunkName: "component---src-pages-ra-033-01-startup-pc-ptn-b-js" */),
  "component---src-pages-ra-040-01-startup-sp-04-js": () => import("./../../../src/pages/ra_040-01_startup_sp_04.js" /* webpackChunkName: "component---src-pages-ra-040-01-startup-sp-04-js" */),
  "component---src-pages-ra-043-startup-sp-05-js": () => import("./../../../src/pages/ra_043_startup_sp_05.js" /* webpackChunkName: "component---src-pages-ra-043-startup-sp-05-js" */),
  "component---src-pages-ra-044-01-pixcel-03-ncr-js": () => import("./../../../src/pages/ra_044-01_pixcel03ncr.js" /* webpackChunkName: "component---src-pages-ra-044-01-pixcel-03-ncr-js" */),
  "component---src-pages-ra-045-01-pixcel-03-ncr-js": () => import("./../../../src/pages/ra_045-01_pixcel03ncr.js" /* webpackChunkName: "component---src-pages-ra-045-01-pixcel-03-ncr-js" */),
  "component---src-pages-ra-045-02-pixcel-03-ncr-js": () => import("./../../../src/pages/ra_045-02_pixcel03ncr.js" /* webpackChunkName: "component---src-pages-ra-045-02-pixcel-03-ncr-js" */),
  "component---src-pages-ra-052-01-pixcel-03-ncr-js": () => import("./../../../src/pages/ra_052-01_pixcel03ncr.js" /* webpackChunkName: "component---src-pages-ra-052-01-pixcel-03-ncr-js" */),
  "component---src-pages-ra-052-02-pixcel-03-ncr-js": () => import("./../../../src/pages/ra_052-02_pixcel03ncr.js" /* webpackChunkName: "component---src-pages-ra-052-02-pixcel-03-ncr-js" */),
  "component---src-pages-ra-053-01-pixcel-03-ncr-js": () => import("./../../../src/pages/ra_053-01_pixcel03ncr.js" /* webpackChunkName: "component---src-pages-ra-053-01-pixcel-03-ncr-js" */),
  "component---src-pages-tvh-5-hdp-tn-adyp-7-whb-js": () => import("./../../../src/pages/tvh5hdpTNAdyp7whb.js" /* webpackChunkName: "component---src-pages-tvh-5-hdp-tn-adyp-7-whb-js" */),
  "component---src-templates-advertise-template-js": () => import("./../../../src/templates/advertise-template.js" /* webpackChunkName: "component---src-templates-advertise-template-js" */)
}

